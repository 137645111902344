import { HouseSchemaType } from '@/dto/taskmap/Dto';
import { color } from '@/styles/mixins';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import ComputerIcon from '@mui/icons-material/Computer';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

// Конфигурация для каждого типа схемы
const schemaTypeConfig = {
  [HouseSchemaType.TWO_GIS_INCORRECT]: {
    tooltip:
      'Внимание! Данные с картографических сервисов могут быть неточными. Рекомендуется проверить информацию',
    icon: <ErrorOutlineRoundedIcon color='warning' />,
    border: `2px solid ${color('muiWarning')}`,
  },
  [HouseSchemaType.TWO_GIS]: {
    tooltip: 'Всё в порядке. Данные с картографических сервисов проверены и актуальны',
    icon: <CheckCircleOutlineRoundedIcon color='success' />,
    border: `2px solid ${color('muiSuccess')}`,
  },
  [HouseSchemaType.CUSTOM]: {
    tooltip: 'Схема дома отредактирована пользователем',
    icon: <PersonRoundedIcon color='success' />,
    border: `2px solid ${color('muiSuccess')}`,
  },
  [HouseSchemaType.DEFAULT]: {
    tooltip: 'Схема дома сгенерирована автоматически',
    icon: <ComputerIcon color='info' />,
    border: `2px solid ${color('muiBlue')}`,
  },
};

export const getShemaTypeView = (type?: HouseSchemaType) => {
  return type ? schemaTypeConfig[type] : schemaTypeConfig[HouseSchemaType.DEFAULT];
};
