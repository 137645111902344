import { TASK_UUID_REG_EXP } from '@/components/widgets/Navbar/utils';

const COORDS_PATTERN = /^[0-9.]+$/;
const WRONG_FORMAT = 'Некорректный формат';

export const HOUSE_PNR_BROWSE_EDIT_ADDRESS_MODAL_VALIDATION = {
  fias: {
    pattern: {
      value: new RegExp(TASK_UUID_REG_EXP),
      message: WRONG_FORMAT,
    },
  },
  coords: {
    pattern: {
      value: COORDS_PATTERN,
      message: WRONG_FORMAT,
    },
  },
};
