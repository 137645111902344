import { ApiResponse } from './SupplyBillService';
import { HOST } from '@/services/YodaRestService';
import { axiosDelete, axiosGet, axiosPost } from '@/services/helpers';
import { TagsResponse } from '@/dto/TagsDto';

export async function getTags(taskId: string): ApiResponse<TagsResponse[]> {
  const url = HOST + `/rest/task-tag/${taskId}/task`;
  return axiosGet(url);
}
export async function createTag(tagName: string): ApiResponse<{ id: string; tag: string }> {
  const url = HOST + `/rest/tag`;
  return axiosPost(url, { tag: tagName });
}
export async function addTagToTasks(taskIds: string[], tagId: string): ApiResponse<TagsResponse[]> {
  const url = HOST + `/rest/task-tag`;
  return axiosPost(url, { taskIds, tagId });
}
export async function deleteTag(taskTagId: string): ApiResponse<TagsResponse> {
  const url = HOST + `/rest/task-tag/${taskTagId}`;
  return axiosDelete(url);
}
