import { useActionLog } from '@/hooks/ActionLogHook';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { KEYS } from '@/hooks/useQuery/Keys';
import { FetchCathError } from '@/types/Types';
import { getTags } from '@/services/TagsService';
import { TagsResponse } from '@/dto/TagsDto';

export function useTags(taskId: string) {
  const { fetchCatch } = useActionLog();
  const [data, setData] = useState<TagsResponse[]>([]);
  const [isExist, setIsExist] = useState(false);

  const { error, isFetching } = useQuery({
    enabled: !!taskId,
    queryKey: [KEYS.tags, taskId],
    refetchOnWindowFocus: false,
    queryFn: () => getTags(taskId),
    onSuccess: (res) => {
      setData(res.data);
      setIsExist(!!res.data);
    },
    onError: (err: FetchCathError) => {
      setIsExist(false);
      fetchCatch(err, 'Ошибка получения тегов');
    },
  });

  // Мемоизация результата хука для предотвращения ненужных запросов
  const memoizedResult = useMemo(
    () => ({
      data,
      error,
      isFetching,
      isExist,
    }),
    [data, error, isFetching, isExist]
  );

  return memoizedResult;
}
