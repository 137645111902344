import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Popup, IPopup } from '@/components/popups/Popup';
import { LoadingButton } from '@mui/lab';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useCatalog } from '@/hooks/CatalogHook';
import { FormProvider, useForm } from 'react-hook-form';
import { addTagToTasks, createTag } from '@/services/TagsService';
import { TagsResponse } from '@/dto/TagsDto';
import { addSuccessActionLog, useActionLog } from '@/hooks/ActionLogHook';
interface Props extends IPopup {
  handleUpdate?: (tags: TagsResponse[]) => void;
  taskIds?: string[];
}

interface AddTagForm {
  tag: string | null;
}

export const AddTagPopup: React.FC<Props> = (props) => {
  const { fetchCatch } = useActionLog();
  const { handleUpdate, onClose, taskIds } = props;
  const methods = useForm<AddTagForm>();
  const { handleSubmit, watch } = methods;
  const tagValue = watch('tag');
  const { getCatalogMapWithErr, updateCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    updateCatalogMapWithErr: state.updateCatalogMapWithErr,
  }));
  const [isLoading, setIsLoading] = useState(false);

  const addTag = (tagId: string) => {
    taskIds?.length &&
      addTagToTasks(taskIds, tagId)
        .then(({ data }) => {
          handleUpdate?.(data);
          addSuccessActionLog('Тег добавлен');
        })
        .catch((err) => fetchCatch(err, 'Ошибка добавления тега'))
        .finally(() => {
          onClose();
          setIsLoading(false);
        });
  };

  const handleSave = (b: AddTagForm) => {
    if (taskIds?.length && b.tag) {
      setIsLoading(true);
      if (b.tag && getCatalogMapWithErr('tags')?.get(b.tag)) {
        addTag(b.tag);
      } else {
        createTag(b.tag)
          .then(({ data }) => {
            updateCatalogMapWithErr('tags');
            addTag(data.id);
          })
          .catch((err) => fetchCatch(err, 'Ошибка добавления тега в каталог'))
          .finally(() => setIsLoading(false));
      }
    }
  };

  return (
    <Popup {...props}>
      <FormProvider {...methods}>
        <Typography
          variant='h5'
          mb={3}
          textAlign={'center'}
        >
          Внесите тег
        </Typography>
        <Grid
          container
          minWidth={300}
          mb={3}
          alignItems={'center'}
          justifyContent={'center'}
          display={'flex'}
        >
          <Grid
            item
            xs={6}
          >
            <AutocompleteValue
              fieldName={`tag`}
              values={getCatalogMapWithErr('tags')}
              freeSolo
              title='Тег'
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={'center'}
          justifyContent={'center'}
          spacing={4}
        >
          <Grid item>
            <LoadingButton
              size='small'
              onClick={handleSubmit(handleSave)}
              loading={isLoading}
              loadingPosition='start'
              variant='text'
              disabled={!tagValue}
            >
              ок
            </LoadingButton>
          </Grid>
        </Grid>
      </FormProvider>
    </Popup>
  );
};
