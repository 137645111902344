import { styled, Tooltip, IconButton, tooltipClasses, TooltipProps } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Loading } from '@/components/ui/Loading';

export const HousePnrBrowseWrapper = styled('div')({
  position: 'relative',

  '.parent': {
    padding: 0,
  },
});

export const HousePnrBrowseInputsWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
  alignItems: 'flex-end',
  gap: 16,
});

export const HousePnrBrowseDataGridWrapper = styled(DataGrid)({
  height: '650px',
  p: {
    margin: 0,
  },
});

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

export const HousePnrBrowseInfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  maxWidth: 700,
  width: 'fit-content',
  minWidth: 300,
});

export const HousePnrBrowseInfoBold = styled('span')({
  fontWeight: 500,
});

export const HousePnrBrowseCloseIconButton = styled(IconButton)({
  marginRight: 'auto',
});

export const HousePnrBrowseInfoLoading = styled(Loading)({
  height: '24px !important',
});
