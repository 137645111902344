import React, { FC, MouseEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { putTransformerAmperage } from '@/services/TransformerService';
import { TransformerBlock } from './TransformerBlock';
import { Button, Grid, Stack, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { ExpandButton } from '@/components/button/ExpandButton';
import { useGalleryData } from '@/components/Gallery/store';
import { useTransformerAmperage } from '@/hooks/useQuery/useTransformerAmperage';
import moment from 'moment';
import { useCatalog } from '@/hooks/CatalogHook';
import { convertToOldApi } from '@/components/Transformer/TransformerAmperage/utils';
import { getTransImageGroup } from '@/components/features/taskEditor/panel/utils';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { ConfirmationDialog } from '@/components/common/ConfirmationDialog';
import { useDisabledAll } from '@/hooks/useDisabledAll';
import { DownloadPopup } from '@/components/popups/DownloadPopup/DownloadPopup';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

interface ITransformerAmperage {
  id: string;
  type: 'task' | 'vru-input';
}

const TransformerAmperage: FC<ITransformerAmperage> = ({ id, type }) => {
  const onlyShow = useTaskEditorOnlyShow();
  const disabledAll = useDisabledAll();
  const methods = useForm();
  const [open, setOpen] = useState<boolean>(false);
  const [openOldPUIndicationIncorrect, setOpenOldPUIndicationIncorrect] = useState(false);
  const [isOpenDownload, setIsOpenDownload] = useState<boolean>(false);

  const { getCatalogMapWithErr } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
  }));

  const { catchError, addActionLog, fetchCatch } = useActionLog();
  const { handleSubmit, setValue } = methods;
  const { data, isExist } = useTransformerAmperage(id, type);

  const setImageGroups = useGalleryData(({ setImageGroups }) => setImageGroups);
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setImageGroups(getTransImageGroup(data));
  };

  const onSave = (e: any) => {
    !onlyShow &&
      putTransformerAmperage(id, type, convertToOldApi(e))
        .then(() => addActionLog(ActionLogType.SUCCESS, 'Успешно сохранено'))
        .catch((err) => fetchCatch(err, err?.response?.data?.message));
  };

  const handleOldPUIndicationsIncorrect = () => {
    // if (replacementResponse) {
    //   // setIsInProgress(true);
    //   oldPUIndicationsIncorrect(replacementResponse.id)
    //     .then(() => {
    //       addActionLog(
    //         ActionLogType.SUCCESS,
    //         `Показания старого ПУ заменены на "некорректная индикация"`
    //       );
    //       updateReplacementResponse && updateReplacementResponse();
    //     })
    //     .catch((err: FetchCathError) => fetchCatch(err));
    // .finally(() => setIsInProgress(false));
    // }
  };

  return isExist ? (
    <FormProvider {...methods}>
      <BoxShadow onDoubleClick={handleClick}>
        {type == 'vru-input' && (
          <DownloadPopup
            id={id}
            isOpen={isOpenDownload}
            onClose={() => setIsOpenDownload(false)}
            isTT
          />
        )}
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography>
            <span style={{ fontWeight: 'bold', marginRight: '16px' }}>
              {data?.smrDate ? moment(data.smrDate).format('DD.MM.yyyy') : 'Дата не указана'}
            </span>
            <span style={{ fontWeight: 'bold', marginRight: '16px' }}>
              {data?.montageName ?? ''}
            </span>
            {!open && (
              <>
                <span style={{ fontWeight: 'bold', marginRight: '16px' }}>{`ТТ: ${
                  data?.mount?.type ?? ''
                }`}</span>
                <span style={{ fontWeight: 'bold' }}>{`КТТ: ${
                  data?.ktt ? getCatalogMapWithErr('ktt').get(data.ktt) ?? data.ktt : ''
                }`}</span>
              </>
            )}
          </Typography>
          {!open ? (
            <div>
              <span style={{ fontWeight: 'bold' }}>Замена/Установка ТТ</span>
              <ExpandButton
                onAction={() => setOpen(!open)}
                expand={open}
              />
            </div>
          ) : (
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <FileDownloadRoundedIcon
                fontSize={'small'}
                sx={{ mr: 1, color: 'gray', cursor: 'pointer' }}
                onClick={() => setIsOpenDownload(true)}
              />
            </div>
          )}
        </Stack>
        {open && (
          <Grid
            mt={2}
            container
            spacing={2}
          >
            {data && <TransformerBlock data={data} />}
            <Grid>
              <Button
                variant='text'
                size='small'
                onClick={() => setOpenOldPUIndicationIncorrect(true)}
                // disabled={disabledAll || onlyShow}
                disabled
              >
                Показания старого ПУ некорректны
              </Button>
              <ConfirmationDialog
                open={openOldPUIndicationIncorrect}
                title='Предупреждение'
                handleCancel={() => setOpenOldPUIndicationIncorrect(false)}
                handleOk={() => {
                  handleOldPUIndicationsIncorrect();
                  setOpenOldPUIndicationIncorrect(false);
                }}
                textForOk='да'
              >
                <span>
                  Данные в показаниях старого ТТ будут безвозвратно заменены на &ldquo;некорректная
                  индикация&rdquo;.
                  <br /> Подтвердить?
                </span>
              </ConfirmationDialog>
            </Grid>
            <Grid sx={{ ml: 'auto' }}>
              <SaveIcon
                onClick={handleSubmit(onSave)}
                sx={{
                  color: 'gray',
                  fontSize: 22,
                  cursor: onlyShow ? '' : 'pointer',
                  ml: 2,
                }}
              />
              <ExpandButton
                onAction={() => setOpen(!open)}
                expand={open}
              />
            </Grid>
          </Grid>
        )}
      </BoxShadow>
    </FormProvider>
  ) : null;
};

export default TransformerAmperage;
