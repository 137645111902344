import { BaseUI } from '@/types/Types';
import React, { useMemo } from 'react';
import { HouseEntranceFlatCountMatchedWrapper } from './HouseEntranceFlatCountMatched.styled';
import { Tooltip } from '@mui/material';
import { usePNRStore } from '../store';
import { getShemaTypeView } from './utils';

const HouseEntranceFlatCountMatched = ({ children, className }: BaseUI) => {
  const schemaType = usePNRStore((state) => state.backendLayoutData)?.schemaType;

  const shemaTypeView = useMemo(() => getShemaTypeView(schemaType), [schemaType]);

  return (
    <HouseEntranceFlatCountMatchedWrapper
      border={shemaTypeView.border}
      className={className}
    >
      {children}
      <sup>
        <Tooltip
          title={shemaTypeView.tooltip}
          placement='top'
        >
          <span>{shemaTypeView.icon}</span>
        </Tooltip>
      </sup>
    </HouseEntranceFlatCountMatchedWrapper>
  );
};

export default HouseEntranceFlatCountMatched;
