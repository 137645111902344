const colors = {
  trueWhite: '#FFFFFF',
  trueBlack: '#000000',
  gray: '#818181BD',
  grayLabel: '#9A9A9A',
  trueGray: '#ccc',
  darkGray: '#999',
  searchGray: '#858585',
  textGray: '#4E4E4E',
  lightBlue: '#6EA8E2',
  lightWhite: '#FAFCFF',
  gatewayGreen: '#D1EECE',
  gatewayGreen2: '#B2E3AD',
  blue: '#0288d1',
  blue2: '#3333CC',
  blue3: '#2196F3',
  muiBlue: '#1976d2',
  aBlue: '#0a58ca',
  muiError: '#d32f2f',
  mintGreen: '#ADDDD9',
  salmon: '#F8C2BF',
  cream: '#EFF4C0',
  trueRed: '#FF0000',
  red1: '#E31919', //еще один красный цвет
  yellow: '#FFFF00',
  purple: '#A555FF',
  lightGreen: '#e7f7e7',
  green: '#40855B',
  assignTaskColorMap: '#FF0D00',
  newTaskColorMap: '#009F49',
  returnedTaskColorMap: '#F3A000',
  disputTaskColorMap: '#6F879C',
  mapBackgroundColorBoxShadowUrgent: '#FDF4F3',
  mapBackgroundColorBoxShadow: '#F5F9FF',
  typographyColorMap1: '#09203E',
  typographyColorMap2: '#6F879C',
  newCall: '#196DFF',
  agreeToChange: '#3CB200',
  refuseToChange: '#878787',
  missedCall: '#C77204',
  callBackCall: '#B51EFF',
  nothingTodoWithTheAddress: '#003459',
  addNumber: '#308095',
  willStrimColor: '#5237DE',
  statusSuccess: '#00861D80',
  statusError: '#C55E0080',
  statusCancel: '#49494980',
  pink: '#EB1E61',
  darkGreen: '#009688',
  sandy: '#FFBD70',
  chronologyTKO: '#700000',
  chronologyReplacement: '#006428',
  chronologyCreate: '#868686',
  chronologyLine: '#D3D3D3',
  mailLine: '#B8B8B8',
  slightYellow: '#FDFADF',
  slightGray: '#F2F2F2',
  successGreen: '#11D50012',
  fontGray: '#303030',
  borderGray: '#C1C1C1',
  warningRed: '#B40000',
  pointGreen: '#51DD50',
  pointPurple: '#9B00FF',
  pointYellow: '#FFFF00',
  fontLightGray: '#6B6B6B',
  gray1: '#BDBDBD',
  gray2: '#ABABAB',
  muiSuccess: '#2e7d32',
  muiWarning: '#ed6c02',
  gray3: '#EDEDED',
  black1: '#000000DE',
  iconRed: '#F20808',
  iconGreen: '#2B7D25',
};

export type Colors = keyof typeof colors;

export default colors;
