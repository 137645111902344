import { BoxShadow } from '@/components/ui/BoxShadow';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Skeleton,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { FC, useEffect, useState } from 'react';
import { useTags } from '@/components/Tags/useTags';
import { Tag } from '@/components/Tags/Tag';
import { TagsWrapper } from '@/components/Tags/Tags.styled';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { AddTagPopup } from '@/components/Tags/AddTagPopup';
import { TagsResponse } from '@/dto/TagsDto';

interface ITags {
  taskId: string;
}

export const Tags: FC<ITags> = ({ taskId }) => {
  const { isFetching, data } = useTags(taskId);

  const [tagsData, setTagsData] = useState<TagsResponse[]>([]);
  useEffect(() => {
    setTagsData(data);
  }, [isFetching]);
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false);
  return (
    <>
      {isFetching ? (
        <Skeleton animation='wave' />
      ) : (
        <BoxShadow p={0}>
          <Accordion
            elevation={0}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>Теги</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {tagsData.length > 0 && (
                <TagsWrapper>
                  {tagsData.map((tag) => (
                    <Tag
                      id={tag.taskTagId}
                      key={tag.taskTagId}
                      tagName={tag.tag}
                      handleUpdate={() =>
                        setTagsData(tagsData.filter((elem) => elem.taskTagId !== tag.taskTagId))
                      }
                    />
                  ))}
                </TagsWrapper>
              )}
              <Button
                sx={{ display: 'flex', margin: '0 auto' }}
                onClick={() => setIsOpenAddPopup(true)}
                variant={'text'}
                startIcon={<AddRoundedIcon />}
              >
                Добавить тег
              </Button>
            </AccordionDetails>
          </Accordion>
        </BoxShadow>
      )}
      <AddTagPopup
        isOpen={isOpenAddPopup}
        onClose={() => setIsOpenAddPopup(false)}
        handleUpdate={(tag) => {
          const isExsistTag = tagsData.find((elem) => elem.taskTagId === tag[0].taskTagId);
          !isExsistTag && setTagsData([...tagsData, ...tag]);
        }}
        taskIds={[taskId]}
      />
    </>
  );
};
