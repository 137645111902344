import { Box, Grid, Skeleton, Stack } from '@mui/material';
import { TextField } from '@/components/fixUI';
import { ErrorBoundary } from '@sentry/react';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { TaskRequest, TaskResponse } from '@/dto/taskmap/task/TasksDto';
import { ActionLogType, useActionLog } from '@/hooks/ActionLogHook';
import { useCatalog } from '@/hooks/CatalogHook';
import { findTaskById } from '@/services/TaskService';
import { useParams } from 'react-router-dom';
import { BoxShadow } from '@/components/ui/BoxShadow';

import { PersistentDrawerRight } from '@/components/ImageDrawer';
import { Panel as PillarPanel } from '@/components/features/taskEditor/pillar/Panel';
import { Panel as AdpuPanel } from '@/components/features/taskEditor/adpu/Panel';
import { useGsmStore } from '@/components/GSM/store';
import {
  TaskEditorHeightWrapper,
  TaskEditorImagePannelWrapper,
  TaskEditorScrollWrapper,
} from './TaskEditor.styled';
import { FetchCathError } from '@/types/Types';
import { ImagePanel } from '@/components/features/taskEditor/panel/ImagePannel/ImagePanel';
import { OdpuInfoPanel } from '@/components/features/taskEditor/panel/OdpuInfoPanel';
import { OdpuPanel } from '@/components/features/taskEditor/panel/OdpuPanel';
import { ReplacementPanel } from '@/components/features/taskEditor/panel/ReplacementPanel';
import { TaskCommentPanel } from '@/components/features/taskEditor/panel/TaskCommentPanel';
import {
  TaskControlPanel,
  screenPosition,
} from '@/components/features/taskEditor/panel/TaskControlPanel/TaskControlPanel';
import { TaskInfoPanel } from '@/components/features/taskEditor/panel/TaskInfoPanel';
import { useStatus } from '@/components/features/taskEditor/panel/store';
import { TaskSubscribersPanel } from '@/components/features/taskEditor/panel/TaskSubscribersPanel';
import { useTaskEditorOnlyShow } from '@/components/features/taskEditor/useTaskEditorOnlyShow';
import { Tags } from '@/components/Tags/Tags';
import { useMetaTitle } from '@/hooks/useMetaTitle';

const TaskEditorHeader = lazy(
  () => import('@/components/features/taskEditor/panel/TaskHeaderPanel')
);

export const TaskEditor = () => {
  const onlyShow = useTaskEditorOnlyShow();
  const methods = useForm<TaskRequest>();
  const { taskId } = useParams();
  const { addActionLog, fetchCatch } = useActionLog();
  const { getCatalogMapWithErr, taskGroupMap, loading } = useCatalog((state) => ({
    getCatalogMapWithErr: state.getCatalogMapWithErr,
    loading: state.loading,
    taskGroupMap: state.taskGroupMap,
  }));
  const [taskResponse, setTaskResponse] = useState<TaskResponse>();
  const [loadingHeader, setLoadingHeader] = useState(false);

  const [reloadVar, setReloadVar] = useState(false);
  const reloadThis = () => {
    setReloadVar(!reloadVar);
  };
  const { setTaskStatus } = useStatus((state) => ({
    setTaskStatus: state.setTaskStatus,
  }));
  const { getGsm } = useGsmStore((store) => ({
    getGsm: store.getGsmData,
  }));

  // useEffect(() => {
  //   taskId && getGsm(taskId);
  // }, [taskId, reloadVar]);

  useEffect(() => {
    if (taskId) {
      findTaskById(taskId)
        .then((res) => {
          setTaskResponse(res.data);
          setTaskStatus(res.data.status);
        })
        .catch((err: FetchCathError) => fetchCatch(err, 'Заявка не найдена'));
    }
  }, [taskId, reloadVar]);

  useEffect(() => {
    setLoadingHeader(
      !(
        taskGroupMap.size > 0 &&
        getCatalogMapWithErr('taskPriority').size > 0 &&
        getCatalogMapWithErr('taskStatus').size > 0 &&
        getCatalogMapWithErr('taskType').size > 0
      )
    );
  }, [taskGroupMap, loading]);

  const onErrorHandle = (error: unknown, componentStack?: string) => {
    if (error instanceof Error) {
      addActionLog(ActionLogType.ERROR, 'Неизвестная ошибка!', error.message, componentStack);
    }
  };

  useMetaTitle(`Заявка ${taskResponse?.number ?? ''} - редактирование`);

  return (
    <>
      <FormProvider {...methods}>
        <Box m={3}>
          <Grid
            container
            spacing={2}
            sx={{ alignItems: 'flex-start' }}
          >
            <Grid
              item
              xs={12}
            >
              <ErrorBoundary onError={onErrorHandle}>
                <Suspense fallback={<Skeleton animation='wave' />}>
                  <BoxShadow sx={{ position: 'relative' }}>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        xs={21 / 2}
                      >
                        <TaskEditorHeader taskResponse={taskResponse!} />
                      </Grid>
                      <Grid
                        item
                        xs={3 / 2}
                      >
                        <TaskControlPanel
                          reload={reloadThis}
                          positionOnScreen={screenPosition.TOP}
                          taskResponse={taskResponse}
                          handleChangeTask={(taskResponse) => setTaskResponse(taskResponse)}
                        />
                      </Grid>
                    </Grid>
                  </BoxShadow>
                </Suspense>
              </ErrorBoundary>
            </Grid>
            <TaskEditorHeightWrapper>
              <TaskEditorScrollWrapper
                item
                xs={5 / 2}
              >
                <Stack spacing={2}>
                  {taskResponse?.id && (
                    <TaskCommentPanel
                      callCenterComments={taskResponse?.callCenterComments}
                      taskId={taskResponse?.id}
                    />
                  )}
                  {taskResponse?.id && <Tags taskId={taskResponse.id} />}
                  <BoxShadow>
                    {taskResponse?.type === 'SMR_ODPU' ||
                    taskResponse?.type === 'SMR_ODPU_MOEK' ||
                    taskResponse?.type === 'CLAIM_ODPU' ||
                    taskResponse?.type === 'SMR_ODPU_ENTITY' ? (
                      <OdpuInfoPanel taskResponse={taskResponse!} />
                    ) : (
                      <TaskInfoPanel taskResponse={taskResponse!} />
                    )}
                    <div style={{ marginTop: 16 }}>
                      <TaskControlPanel
                        reload={reloadThis}
                        positionOnScreen={screenPosition.LEFT}
                        taskResponse={taskResponse}
                        handleChangeTask={(taskResponse) => setTaskResponse(taskResponse)}
                      />
                    </div>
                  </BoxShadow>
                  {taskResponse?.id && <TaskSubscribersPanel taskId={taskResponse?.id} />}
                  {taskResponse?.status === 'DISPUT' && (
                    <BoxShadow>
                      <TextField
                        multiline
                        disabled
                        label='Сообщение об ошибке'
                        value={taskResponse?.errorMessage}
                      />
                    </BoxShadow>
                  )}
                </Stack>
              </TaskEditorScrollWrapper>
              <TaskEditorScrollWrapper
                item
                xs={19 / 2}
              >
                {taskResponse?.type === 'SMR_ODPU' ||
                taskResponse?.type === 'SMR_ODPU_MOEK' ||
                taskResponse?.type === 'CLAIM_ODPU' ||
                taskResponse?.type === 'SMR_ODPU_ENTITY' ? (
                  <OdpuPanel
                    taskResponse={taskResponse}
                    reloadParent={reloadThis}
                    reloadParentFlag={reloadVar}
                  />
                ) : taskResponse?.type === 'PPO_TP' ? (
                  !onlyShow && (
                    <PillarPanel
                      taskResponse={taskResponse!}
                      reloadParent={reloadThis}
                      reloadParentFlag={reloadVar}
                    />
                  )
                ) : taskResponse?.type === 'SIGNE_ADPU' ? (
                  !onlyShow && (
                    <AdpuPanel
                      taskResponse={taskResponse!}
                      reloadParent={reloadThis}
                      reloadParentFlag={reloadVar}
                    />
                  )
                ) : (
                  <ReplacementPanel
                    taskResponse={taskResponse!}
                    reloadParent={reloadThis}
                    reloadParentFlag={reloadVar}
                  />
                )}
                {/*<DeliveryPointPanel />*/}
              </TaskEditorScrollWrapper>
              <TaskEditorImagePannelWrapper
                item
                xs={3}
              >
                <Stack
                  spacing={2}
                  height={'100%'}
                >
                  <BoxShadow
                    sx={{
                      overflow: 'auto',
                    }}
                  >
                    <ImagePanel />
                  </BoxShadow>
                  <PersistentDrawerRight />
                </Stack>
              </TaskEditorImagePannelWrapper>
            </TaskEditorHeightWrapper>
          </Grid>
        </Box>
      </FormProvider>
    </>
  );
};
