import { Loading } from '@/components/ui/Loading';
import {
  HouseEntranceShemeFullEntrance,
  HouseEntranceShemeModal,
} from '@/components/sections/HouseEntranceSheme/HouseEntranceSheme.styled';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { usePNRStore } from '../../store';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  PnrDndEditHouseModalForm,
  PnrDndEditModalCenterWrapper,
  PnrDndEditModalInner,
  PnrDndEditModalShemeWrapper,
  PnrDndEditModalTrash,
} from './PnrDndEditModal.styled';
import PnrDndEditModalFloor from './PnrDndEditModalFloor';
import { useHouseDnDEdit } from './utils';
import PnrDndEditModalEntranceHeader from './PnrDndEditModalEntranceHeader';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import { KEYS } from '@/hooks/useQuery/Keys';
import { useIsMutating } from 'react-query';
import { addSuccessActionLog, catchError } from '@/hooks/ActionLogHook';
import { ApiUpdateHouseLayout } from '@/services/YodaRestService';
import { LoadingButton } from '@mui/lab';

const PnrDndEditModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isFetchingHouseEntranceData = useIsMutating(KEYS.pnrHouseEntranceData);
  const layoutData = usePNRStore((state) => state.layoutData);
  const houseData = usePNRStore((state) => state.houseData);
  const setLayoutDataFromBackend = usePNRStore((state) => state.setLayoutDataFromBackend);

  const {
    handleDragEnd,
    updateFloorEntry,
    handleHouseEditSubmit,
    resetDnDModalEditions,
    handleHouseReset,
  } = useHouseDnDEdit();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    resetDnDModalEditions();
  };

  const saveAndCloseModal = () => {
    if (!houseData?.houseId || !layoutData) return;
    setIsLoading(true);
    ApiUpdateHouseLayout(houseData.houseId, layoutData)
      .then(({ data }) => {
        addSuccessActionLog('Успешное сохранение данных');
        setLayoutDataFromBackend(data);
      })
      .catch((error) => catchError('Ошибка сохранения данных', error))
      .finally(() => {
        setIsOpen(false);
        setIsLoading(false);
      });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Tooltip
        title={'Редактирование дома'}
        placement='top'
      >
        <IconButton onClick={handleOpen}>
          <RoomPreferencesIcon />
        </IconButton>
      </Tooltip>
      <HouseEntranceShemeModal
        open={isOpen}
        onClose={handleClose}
      >
        <PnrDndEditModalShemeWrapper>
          <Typography
            variant='h6'
            textAlign='center'
          >
            Редактирование дома
          </Typography>
          <PnrDndEditHouseModalForm
            onSubmit={handleHouseEditSubmit}
            onReset={handleHouseReset}
          />

          <Loading
            loading={!!isFetchingHouseEntranceData}
            sx={{ minHeight: '200px' }}
          >
            <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
              <PnrDndEditModalInner>
                {layoutData?.entrances?.map((entrance) => (
                  <HouseEntranceShemeFullEntrance key={entrance.number}>
                    <PnrDndEditModalEntranceHeader
                      entranceNumber={entrance.number}
                      floorCount={entrance?.floors.length}
                    />

                    {!!entrance?.floors?.length &&
                      entrance?.floors?.map((floor) => (
                        <PnrDndEditModalFloor
                          key={floor.number}
                          entranceNumber={entrance.number}
                          floor={floor}
                          updateFloorEntry={updateFloorEntry}
                        />
                      ))}
                  </HouseEntranceShemeFullEntrance>
                ))}
              </PnrDndEditModalInner>
              <Droppable
                droppableId={`trash`}
                direction='horizontal'
              >
                {(provided, snapshot) => (
                  <PnrDndEditModalTrash
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                    <DeleteForeverRoundedIcon color='inherit' />
                  </PnrDndEditModalTrash>
                )}
              </Droppable>
            </div>

            <PnrDndEditModalCenterWrapper>
              <LoadingButton
                variant='contained'
                onClick={saveAndCloseModal}
                disabled={!!isFetchingHouseEntranceData}
                loading={isLoading || !!isFetchingHouseEntranceData}
              >
                Сохранить
              </LoadingButton>
              <Button onClick={handleClose}>Отмена</Button>
            </PnrDndEditModalCenterWrapper>
          </Loading>
        </PnrDndEditModalShemeWrapper>
      </HouseEntranceShemeModal>
    </DragDropContext>
  );
};

export default PnrDndEditModal;
