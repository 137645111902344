import { Autocomplete, Grid, IconButton, Modal, TextField, LinearProgress } from '@mui/material';
import React, { useState, memo } from 'react';
import { SupplyBillBrowseDto, SupplyPU, SupplyPUItem } from '@/dto/taskmap/Dto';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';
import useSupplyStore, { initialPuList } from '../SupplyStore';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { SupplyConstant } from '../SupplyConstant';
import { ActionLogType, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import PURemoveScanner from '../PURemoveScanner/PURemoveScanner';
import AlertError from '@/components/storehouse/Alert/AlertError';
import AlertRemove from '@/components/storehouse/Alert/AlertRemove';
import {
  ButtonStyled,
  IconButtonStyled,
  SupplyButtonsWrapper,
  Wrapper,
} from '@/components/storehouse/SupplyPUComponent/SupplyPUComponent.styled';
import {
  generateRowNum,
  generateScanId,
  getSavePuListId,
  isNumberExist,
  generateNextId,
  updateRowNums,
  convertIntoFilter,
  PUExistErrorMap,
  useMeterTypes,
  toObject,
  convertLSErrorObjectToMap,
  osDupErrorMap,
  checkErrors,
  useStorehouseLS,
  generatePuExcelFile,
  replaceNonNums,
  TextFieldChangeEvent,
  useStorehouseWorkerAccess,
  PuList_mock_TEST,
  SupplyPUItemConfirmState,
  splitDoubleNumber,
  isChildType,
  osNoYodaTaskErrorMap,
} from './utils';
import { SupplyBillComponentProps, SupplyTabType } from '../StampComponent/StampComponent';
import { SupplyPUModalInner } from '../PURemoveScanner/PURemoveScanner.styled';
import { SupplyBillApiCheckSupplyPuExists } from '@/services/SupplyBillService';
import { SupplyBillBrowseLinearProgressWrapper } from '../SupplyBillBrowse/SupplyBillBrowse.styled';
import { playAudioByKey } from '@/utils/heplers';
import SupplyPUVirtualizedTable from './SupplyPUVirtualizedTable';
import { useAllPuTypes } from '@/hooks/useQuery/useAllPuTypes';
import SuppluPUModalHeader from './SupplyPUModalHeader';
import {
  useAllPuCompleteness,
  useAllPuOwner,
  useAllEquipmentState,
  useAllPuDefectCause,
  useAllOsDefectCause,
} from '@/hooks/useQuery/useAllSupplyCatalogs';
import SupplyClear from '../SupplyClear/SupplyClear';
import shallow from 'zustand/shallow';
import {
  SaveOsItem,
  SavePuItem,
} from '../SupplyReturnBillExtraColumns/SupplyReturnBillExtraColumns';
import { checkNonConfirmedItems } from '../SupplyButtonPanel/utils';
import { GetTaskByMeter } from '@/services/YodaRestService';

export type SelectedPu = SupplyPU & { meterTypeScanField: string; parentTypeName: string };

interface SupplyPUComponentProps extends SupplyTabType, SupplyBillComponentProps {}

const SupplyPUComponent = (props: SupplyPUComponentProps) => {
  const {
    puList,
    setPUList,
    billNumber,
    supplyMeterTypes,
    setSupplyMeterTypes,
    setReturnNewItemsMode,
    setForcedTaskNumberInfo,
    forcedTaskNumberInfo,
    returnNewItemsMode,
    billType,
  } = useSupplyStore(
    (state) => ({
      puList: state.puList,
      setPUList: state.setPUList,
      billNumber: state.billNumber,
      supplyMeterTypes: state.supplyMeterTypes,
      setSupplyMeterTypes: state.setSupplyMeterTypes,
      setReturnNewItemsMode: state.setReturnNewItemsMode,
      returnNewItemsMode: state.returnNewItemsMode,
      setForcedTaskNumberInfo: state.setForcedTaskNumberInfo,
      forcedTaskNumberInfo: state.forcedTaskNumberInfo,
      billType: state.billType,
    }),
    shallow
  );

  const returnBill =
    billType === SupplyConstant.RETURNED_TYPE ||
    billType === SupplyConstant.DRAFT_TYPE_RETURN ||
    billType === SupplyConstant.RETURNED_TYPE_WAITING_APPROVAL_PROJECT ||
    billType === SupplyConstant.RETURNED_TYPE_AGREED_PROJECT;
  const relocationGetBill = billType === SupplyConstant.RELOCATION_TYPE_GET;
  const relocationPostBill =
    billType === SupplyConstant.DRAFT_TYPE_RELOCATION_POST ||
    billType === SupplyConstant.RELOCATION_TYPE_POST;

  const billWithConfirm = returnBill || relocationGetBill;
  const { nonEditableBill } = props;

  const { getValue, setValue } = useStorehouseLS();
  const workerAccess = useStorehouseWorkerAccess();

  const { puCompletenessOptions, isFetching: isLoadingCompleteness } =
    useAllPuCompleteness(returnBill);
  const { equipmentStateOptions, isFetching: isLoadingState } = useAllEquipmentState(returnBill);
  const { puOwnerOptions, isFetching: isLoadingOwner } = useAllPuOwner(returnBill);
  const { puDefectOptions, isFetching: isLoadingPuDefects } = useAllPuDefectCause(returnBill);
  const { osDefectOptions, isFetching: isLoadingOsDefects } = useAllOsDefectCause(returnBill);

  const lsKey = getSavePuListId(props.tabType, props?.supplyBillDto);

  const [selectedMeterTypesMap, setSelectedMeterTypes] = useState<
    Map<number, SelectFilterOptType | null>
  >(new Map());

  const [meterTypes, setMeterTypes] = useState<SelectFilterOptType[]>([]);
  const [isScanningMode, setIsScanningMode] = useState<boolean>(false);
  const [osDupFieldError, setOsDupFieldError] = useState<osDupErrorMap>(new Map());
  const [osExistsFieldError, setOsExistsFieldError] = useState<PUExistErrorMap>(new Map());
  const [noYodaTaskErrorMap, setNoYodaTaskErrorMap] = useState<osNoYodaTaskErrorMap>(new Map());

  const [editedRow, setEditedRow] = useState<SupplyPUItem>();

  const [selectedPU, setSelectedPU] = useState<SelectedPu>();
  const [selectedPUDeclaredCount, setSelectedPUDeclaredCount] = useState<string>('');
  const [puListItems, setPUListItems] = useState<SupplyPUItem[]>([]);

  const [rowToRemove, setRowToRemove] = useState<number>();

  const [isViewMode, setIsViewMode] = useState<boolean>(false);

  const [alertText, setAlertText] = useState<string>('');
  const handleClose = () => setAlertText('');

  const handleRemoveConfirmClose = () => setRowToRemove(undefined);

  const { catchError, addActionLog } = useActionLog();

  const removeScanRow = (rowId: number) => {
    if (!selectedPU) return;
    const maxId = Math.max(...puListItems.map((o) => o.tmpId));
    if ((puListItems.length > 1 && rowId !== maxId) || returnBill) {
      const arr = [...puListItems];
      const scanIndex = arr.indexOf(arr.find((it) => it.tmpId === rowId)!);
      if (scanIndex !== -1) {
        arr.splice(scanIndex, 1);
        setPUListItems(updateRowNums(arr));
        osDupFieldError?.get(selectedPU.tmpId)?.delete(rowId);
        setOsDupFieldError(new Map(osDupFieldError));
        osExistsFieldError?.get(selectedPU.tmpId)?.delete(rowId);
        setOsExistsFieldError(new Map(osExistsFieldError));

        noYodaTaskErrorMap?.get(selectedPU.tmpId)?.delete(rowId);
        setNoYodaTaskErrorMap(new Map(noYodaTaskErrorMap));

        checkDuplicateAndMatchRegex(arr);
      }
    }
  };

  const confirmScanRow = (rowId: number) => {
    if (!selectedPU) return;
    const arr = [...puListItems];
    const scanIndex = arr.indexOf(arr.find((it) => it.tmpId === rowId)!);
    if (scanIndex !== -1) {
      arr[scanIndex].confirmed = SupplyPUItemConfirmState.bothNumbers;
      setPUListItems(arr);
    }
  };

  const confirmAllRows = () => {
    if (!selectedPU) return;
    const arr = puListItems.map((pu) => ({
      ...pu,
      confirmed: SupplyPUItemConfirmState.bothNumbers,
    }));
    setPUListItems(arr);
  };

  const confirmScanedNumber = (scanedNumber: string) => {
    if (!selectedPU) return;
    const arr = [...puListItems];
    const scanIndex = arr.indexOf(
      arr.find((it) => {
        const { number1, number2 } = splitDoubleNumber(it.number);
        return number1 === scanedNumber || number2 === scanedNumber;
      })!
    );
    const lastEditableCellId = puListItems[puListItems?.length - 1]?.tmpId;
    let scrollIndex: null | number = lastEditableCellId;
    if (scanIndex !== -1) {
      scrollIndex = arr[scanIndex].tmpId;
      if (arr[scanIndex].confirmed !== SupplyPUItemConfirmState.bothNumbers) {
        const { number1, number2 } = splitDoubleNumber(arr[scanIndex].number);

        if (number1 === scanedNumber) {
          if (arr[scanIndex].confirmed === SupplyPUItemConfirmState.secondNumber) {
            arr[scanIndex].confirmed = SupplyPUItemConfirmState.bothNumbers;
          } else {
            arr[scanIndex].confirmed = SupplyPUItemConfirmState.firstNumber;
          }
        }
        if (number2 === scanedNumber) {
          if (arr[scanIndex].confirmed === SupplyPUItemConfirmState.firstNumber) {
            arr[scanIndex].confirmed = SupplyPUItemConfirmState.bothNumbers;
          } else {
            arr[scanIndex].confirmed = SupplyPUItemConfirmState.secondNumber;
          }
        }
      }
    } else {
      if (relocationGetBill) {
        showMessage(`Номер ${scanedNumber} отсутствует в накладной`);
      } else {
        const rowScanId = generateScanId(selectedPU, arr);
        const rowNumId = generateRowNum(selectedPU, arr);
        scrollIndex = rowScanId;
        arr.push({
          tmpId: rowScanId,
          id: null,
          rowNum: rowNumId,
          meterTypeId: selectedPU.meterTypeId,
          meterTypeName: selectedPU.meterTypeName,
          number: scanedNumber,
          mac: null,
          returnStorehouseAdded: true,
        });
      }
    }
    setPUListItems(arr);
    return scrollIndex;
  };

  const removeErroredScanRows = (allIds: number[], removeIds: number[]) => {
    if (!selectedPU) return;
    const arr = [...puListItems];
    const filteredPUs = arr.filter((pu) => !removeIds.includes(pu.tmpId));
    const updatedRowNumsList = updateRowNums(filteredPUs);
    setPUListItems(updatedRowNumsList);
    deletePUListItemsErrors(allIds);

    checkDuplicateAndMatchRegex(filteredPUs);
  };

  const { puTypesResponse, isLoading } = useAllPuTypes();

  const showRemoveConfirm = (rowId: number) => setRowToRemove(rowId);

  const removeRow = () => {
    if (rowToRemove) {
      const arr = [...puList];
      const index = arr.indexOf(arr.find((it) => it.tmpId === rowToRemove)!);
      if (index !== -1) {
        arr.splice(index, 1);
        updatePUList(arr);
        setSelectedMeterTypes(selectedMeterTypesMap.set(rowToRemove, null));
        osExistsFieldError?.delete(rowToRemove);
        setOsExistsFieldError(new Map(osExistsFieldError));

        osDupFieldError?.delete(rowToRemove);
        setOsDupFieldError(new Map(osDupFieldError));

        noYodaTaskErrorMap?.delete(rowToRemove);
        setNoYodaTaskErrorMap(new Map(noYodaTaskErrorMap));
      }
    }
    setRowToRemove(undefined);
  };

  React.useEffect(() => {
    if (puTypesResponse) {
      puTypesResponse?.map((meterType) => {
        setSupplyMeterTypes(new Map(supplyMeterTypes.set(meterType?.id, meterType)));
      });
      setMeterTypes(convertIntoFilter(puTypesResponse));
      try {
        if (props.supplyBillDto?.puList?.length) {
          initPuList(convertIntoFilter(puTypesResponse));
        } else {
          const tmpFromLS =
            //   PuList_mock_TEST(500)
            // getValue()?.[lsKey]?.pu?
            getValue()?.[lsKey]?.pu?.map((el) => ({
              ...el,
              puItems: el.puItems?.filter(
                (pu) => (pu.mac && pu.mac.length > 0) || (pu.number && pu.number.length > 0)
              ),
            })) ?? initialPuList;
          const tmpForMapFromLS: Map<number, SelectFilterOptType | null> = new Map(
            getValue()?.[lsKey]?.pu?.map((elem) => [
              elem.tmpId,
              convertIntoFilter(puTypesResponse)?.find(
                (meterType: SelectFilterOptType) => meterType.value === elem.meterTypeId
              ) ?? null,
            ]) ?? []
          );

          setErrorsFromLS();
          setSelectedMeterTypes(tmpForMapFromLS);
          setPUList(tmpFromLS);
        }
      } catch (err) {
        syncErrorCatch('Ошибка инициализации приборов учета', {
          message: err instanceof Error ? err.message : '',
        });
      }
    }
  }, [puTypesResponse, props.supplyBillDto]);

  const setErrorsFromLS = () => {
    const dupErrorsLS = getValue()?.[lsKey]?.puErrors?.dup;
    if (dupErrorsLS) {
      const dupMap = convertLSErrorObjectToMap<osDupErrorMap>(dupErrorsLS);
      if (dupMap?.size) {
        setOsDupFieldError(dupMap);
      }
    }

    const noYodaTask = getValue()?.[lsKey]?.puErrors?.noYodaTask;
    if (noYodaTask) {
      const noYodaTaskMap = convertLSErrorObjectToMap<osNoYodaTaskErrorMap>(noYodaTask);
      if (noYodaTaskMap?.size) {
        setNoYodaTaskErrorMap(noYodaTaskMap);
      }
    }

    const existErrorsLS = getValue()?.[lsKey]?.puErrors?.exist;
    if (existErrorsLS) {
      const existMap = convertLSErrorObjectToMap<PUExistErrorMap>(existErrorsLS);
      if (existMap?.size) {
        setOsExistsFieldError(existMap);
      }
    }
  };

  React.useEffect(() => {
    if (editedRow) {
      checkDuplicateAndMatchRegex(puListItems);
    }
  }, [editedRow]);

  React.useEffect(() => {
    if (!isScanningMode) {
      setReturnNewItemsMode(false);
      return;
    }
    const tmpMap = checkErrorMap(puListItems);
    setOsDupFieldError(new Map(tmpMap));
  }, [isScanningMode]);

  const { selectedMeterTypes } = useMeterTypes(selectedMeterTypesMap, puList, meterTypes);

  //   Сохранение временных данных в LocalStorage на случай перезагрузки страницы
  React.useEffect(() => {
    if (!puTypesResponse || nonEditableBill || props.withoutLsSave) return;

    const savePuListToLS = (newPuList: SupplyPU[]) => {
      setValue(
        {
          pu: newPuList,
          puErrors: {
            dup: toObject(osDupFieldError),
            exist: toObject(osExistsFieldError),
            noTodaTask: toObject(noYodaTaskErrorMap),
          },
        },
        lsKey
      );
    };

    if (selectedPU) {
      const nonSelectedPus = puList.filter((pu) => selectedPU.tmpId !== pu.tmpId);
      nonSelectedPus.push({
        ...selectedPU,
        puItems: puListItems?.filter((puItem) => !puItem.backendItem),
      });

      savePuListToLS(nonSelectedPus);
    } else {
      const unSavedPuList = puList.map((pu) => {
        if (pu.backendItem) {
          return {
            ...pu,
            puItems: pu.puItems?.filter((puItem) => !puItem.backendItem),
          };
        } else {
          return pu;
        }
      });
      savePuListToLS(unSavedPuList);
    }
  }, [
    puTypesResponse,
    puListItems,
    puList,
    osDupFieldError,
    noYodaTaskErrorMap,
    osExistsFieldError,
  ]);

  const initPuList = (meterTypes: SelectFilterOptType[]) => {
    const supplyBill = { ...props.supplyBillDto } as SupplyBillBrowseDto | undefined;
    const puListFromLS = getValue()?.[lsKey]?.pu;

    const tmp: SupplyPU[] = supplyBill!.puList?.map((elem, index) => {
      const puItemsFromLS =
        puListFromLS
          ?.find((pu) => pu.meterTypeId === elem.meterTypeId)
          ?.puItems?.filter(
            (it) => (it.mac && it.mac.length > 0) || (it.number && it.number.length > 0)
          ) ?? [];
      const mergedCount = (elem.puItems.length + puItemsFromLS.length).toString();

      return {
        ...elem,
        tmpId: index + 1,
        declaredCount: mergedCount,
        puItems: [
          ...elem.puItems.map((el, ind) => ({
            ...el,
            tmpId: ind + 1,
            rowNum: ind + 1,
            meterTypeId: elem.meterTypeId,
            backendItem: true,
          })),
          ...(puItemsFromLS.map((el, index) => ({
            ...el,
            tmpId: elem.puItems.length + index + 1,
          })) ?? []),
        ],
        backendItem: true,
      };
    }) as SupplyPU[];

    const unsavedPusFromLS = puListFromLS
      ?.filter(
        (unsavedPu) => !tmp?.find((backendPu) => backendPu.meterTypeId === unsavedPu.meterTypeId)
      )
      ?.map((unsavedPu, index) => ({
        ...unsavedPu,
        tmpId: tmp.length + index + 1,
      }));

    const getSelectedMeterTypes = (
      values: SupplyPU[] | undefined,
      fromLocalStorage: boolean
    ): [number, SelectFilterOptType | null][] => {
      return (
        values?.map((pu, index) => [
          fromLocalStorage ? generateNextId(tmp) + index : index + 1,
          meterTypes.find((meterType) => meterType.value === pu.meterTypeId) ?? null,
        ]) ?? []
      );
    };
    const tmpForMap = getSelectedMeterTypes(supplyBill?.puList, false);
    const tmpForMapFromLS = getSelectedMeterTypes(unsavedPusFromLS, true);
    const mergedMeterTypes = new Map([...tmpForMap, ...tmpForMapFromLS]);

    setSelectedMeterTypes(mergedMeterTypes);
    setPUList([...tmp, ...(unsavedPusFromLS ?? [])]);

    setErrorsFromLS();
  };

  const deletePUListItemsErrors = (errorRowIds: number[]) => {
    if (!selectedPU) return;
    errorRowIds.forEach((rowId) => {
      osDupFieldError?.get(selectedPU.tmpId)?.delete(rowId);
      osExistsFieldError?.get(selectedPU.tmpId)?.delete(rowId);
      noYodaTaskErrorMap?.get(selectedPU.tmpId)?.delete(rowId);
    });

    setOsDupFieldError(new Map(osDupFieldError));
    setNoYodaTaskErrorMap(new Map(noYodaTaskErrorMap));
    setOsExistsFieldError(new Map(osExistsFieldError));
  };

  const resetState = () => {
    const allUnsavedItemIds = puListItems.filter((el) => !el.savedItem).map((el) => el.tmpId);
    const allSavedItems = puListItems.filter((el) => el.savedItem);
    deletePUListItemsErrors(allUnsavedItemIds);
    checkDuplicateAndMatchRegex(allSavedItems);
    setSelectedPU(undefined);
    setPUListItems([]);
    setIsScanningMode(false);
    setIsViewMode(false);
    setSelectedPUDeclaredCount('');
  };

  const savePUItems = (forcedPuListItems?: SupplyPUItem[]) => {
    const addedItemsCount = billWithConfirm ? puListItems.length : puListItems.length - 1;
    if (addedItemsCount > parseInt(selectedPUDeclaredCount)) {
      showMessage('Добавлено больше чем заявлено', true);
    } else if (addedItemsCount < parseInt(selectedPUDeclaredCount)) {
      showMessage('Добавлено меньше чем заявлено', true);
    } else {
      updatePUItems(forcedPuListItems);
      resetState();
    }
  };

  const removePuByScanning = (itemsToRemove: SupplyPUItem[], meterTypeId: number) => {
    const puListByMeterType = puList.filter((it) => it.meterTypeId === meterTypeId);
    const supplyMeterType = supplyMeterTypes.get(meterTypeId);
    const deletedItems: SupplyPUItem[] = [];
    if (supplyMeterType) {
      puListByMeterType.forEach((pu) => {
        const puItems = [...pu.puItems];
        itemsToRemove.forEach((item) => {
          let puItem;
          if (supplyMeterType.meterTypeScanField.toLowerCase() === 'number' && item.number) {
            puItem = puItems.find((it) => it.number && it.number === item.number);
          } else if (supplyMeterType.meterTypeScanField.toLowerCase() === 'mac' && item.mac) {
            puItem = puItems.find((it) => it.mac && it.mac === item.mac);
          }
          if (puItem) {
            const index = puItems.indexOf(puItem);
            if (index !== -1) {
              deletedItems.push(puItems[index]);
              puItems.splice(index, 1);
              pu.declaredCount = (
                pu.declaredCount ? parseInt(pu.declaredCount) - 1 : ''
              ).toString();
            }
          }
        });
        pu.puItems = [...updateRowNums(puItems)];
        setPUList([...puList]);
      });
    }
    deletedItems.length
      ? addActionLog(ActionLogType.SUCCESS, 'Данные успешно удалены')
      : addActionLog(ActionLogType.ERROR, 'ПУ с введенными данными не найдены');
  };

  const checkDuplicateAndMatchRegex = (listPUItem: SupplyPUItem[]) => {
    if (!selectedPU) return;
    if (editedRow) {
      const val = editedRow.mac ? editedRow.mac : editedRow.number;
      if (val && selectedPU?.puNumRegexExpr && val?.length > 0) {
        const regExp = new RegExp(selectedPU?.puNumRegexExpr);
        if (regExp.test(val)) {
          checkDuplicate(editedRow, listPUItem);
        } else {
          const dupPuList = osDupFieldError.get(selectedPU.tmpId);
          if (dupPuList) {
            dupPuList.set(editedRow.tmpId, true);
            osDupFieldError.set(selectedPU.tmpId, dupPuList);

            setOsDupFieldError(new Map(osDupFieldError));
          } else {
            const newDupMap = new Map();
            newDupMap.set(editedRow.tmpId, true);
            osDupFieldError.set(selectedPU.tmpId, newDupMap);
            setOsDupFieldError(new Map(osDupFieldError));
          }
          showMessage(`Номер ${val} не подходит под формат`);
        }
      } else {
        checkDuplicate(editedRow, listPUItem);
      }
      setEditedRow(undefined);
    } else {
      const tmpMap = checkErrorMap(listPUItem);
      setOsDupFieldError(new Map(tmpMap));
    }
  };

  const checkDuplicate = (pu: SupplyPUItem, listPUItem: SupplyPUItem[]) => {
    if (!selectedPU) return;
    const dupItems = isNumberExist(pu, listPUItem);
    if (dupItems.length > 1) {
      dupItems.forEach((it) => {
        const dupPuList = osDupFieldError.get(selectedPU.tmpId);
        if (dupPuList) {
          dupPuList.set(it.tmpId, true);
          osDupFieldError.set(selectedPU.tmpId, dupPuList);
        } else {
          const newDupMap = new Map();
          newDupMap.set(it.tmpId, true);
          osDupFieldError.set(selectedPU.tmpId, newDupMap);
        }
      });
    } else {
      const dupPuList = osDupFieldError.get(selectedPU.tmpId);
      if (dupPuList) {
        dupPuList.set(pu.tmpId, false);
        osDupFieldError.set(selectedPU.tmpId, dupPuList);
      }
    }
    const tmpMap = checkErrorMap(listPUItem);
    setOsDupFieldError(new Map(tmpMap));
    if (dupItems.length > 1) {
      showMessage(`Дубль! Вы уже отсканировали прибор с номером ${pu?.mac || pu?.number}`);
    }
  };

  const checkErrorMap = (listPUItem: SupplyPUItem[]) => {
    const tmpMap = new Map(osDupFieldError) as osDupErrorMap;
    if (!selectedPU) return tmpMap;
    const currentDups = tmpMap.get(selectedPU.tmpId);
    if (!currentDups) return tmpMap;
    currentDups.forEach((value, key) => {
      if (value) {
        const item = listPUItem.find((it) => it.tmpId === key);
        if (item) {
          const errDupItems = isNumberExist(item, listPUItem);

          currentDups.set(item.tmpId, errDupItems.length > 1);
        }
      }
    });
    tmpMap.set(selectedPU.tmpId, currentDups);
    return tmpMap;
  };

  const showMessage = (text: string, alert?: boolean) => {
    alert ? setAlertText(text) : catchError(text, {});
    playAudioByKey('errorAudio');
  };

  const updatePUItems = (forcedPuListItems?: SupplyPUItem[]) => {
    if (selectedPU) {
      let resultList = (forcedPuListItems ?? puListItems).filter(
        (it) => (it.mac && it.mac.length > 0) || (it.number && it.number.length > 0)
      );
      if (returnBill) {
        resultList = forcedPuListItems ?? puListItems;
      }
      const newPUList = puList.map((obj) => {
        if (obj.tmpId === selectedPU.tmpId) {
          return {
            ...obj,
            puItems: resultList,
            declaredCount: selectedPUDeclaredCount,
          };
        }
        return obj;
      });
      updatePUList(newPUList);
    }
  };

  const onEditCommit = (puItem: SavePuItem | SaveOsItem) => commitNewRow(puItem);

  const onEditStart = (puItem?: SupplyPUItem) => {
    if (!selectedPU) return;
    if (returnBill) {
      return addNewRow(selectedPU.meterTypeId, selectedPU.meterTypeName);
    } else {
      if (puItem) return addNewRowIfNeeded(puItem.tmpId);
    }
  };

  const commitNewRow = (params: SavePuItem | SaveOsItem) => {
    const item = puListItems.find((it) => it.rowNum === params.rowNum)!;
    const supplyMeterType = supplyMeterTypes.get(selectedPU?.meterTypeId ?? -1);
    const updateReturnItem = () => {
      if (
        billType == SupplyConstant.RETURNED_TYPE ||
        billType == SupplyConstant.DRAFT_TYPE_RETURN
      ) {
        if (params.taskNumber && typeof params.taskNumber === 'object') {
          item.taskNumber = params.taskNumber.taskNumberValue;
        }
        item.manufactureYear = params.manufactureYear;
        item.ownerId = params.ownerId;
        item.state = params.state;
        item.defectCause = params.defectCause;
        item.completeness = params.completeness;
        item.completenessComment = params.completenessComment;
        item.supplyComment = params.supplyComment;
        item.address = params.address;
        item.generalComment = params.generalComment;
      }
    };

    if (supplyMeterType) {
      if (supplyMeterType.meterTypeScanField.toLowerCase() === 'mac') {
        item.mac = params.mac;
      } else if (
        supplyMeterType.meterTypeScanField.toLowerCase() === 'number' &&
        'number' in params
      ) {
        item.number = params.number;
      }
    } else {
      item.mac = params.mac;
    }
    returnBill && updateReturnItem();

    setEditedRow(item);
    if (
      item?.meterTypeId > 0 &&
      ((item.mac && item.mac?.length > 0) || (item.number && item.number?.length > 0))
    ) {
      if (returnBill) {
        checkReturnPuItemYodaTaskInfo(item);
      } else {
        checkPuItemExists(item);
      }
    }
  };

  const updateListItems = () => {
    checkDuplicateAndMatchRegex(puListItems);
    const arr = [...puListItems];
    setPUListItems(arr);
  };

  const checkPuItemExists = (item: SupplyPUItem) => {
    SupplyBillApiCheckSupplyPuExists({
      mac: item.mac || null,
      puNumber: item.number || null,
      typeId: item.meterTypeId,
      billTypeId: billType,
    })
      .then(({ data }) => {
        if (!selectedPU) return;
        let errMap = new Map();
        if (
          data?.billNumber &&
          data?.billNumber?.length > 0 &&
          billNumber !== data?.billNumber &&
          !relocationPostBill &&
          !relocationGetBill
        ) {
          const puListErrors = new Map();
          puListErrors.set(item.tmpId, { billName: data.billNumber, item: item });
          if (osExistsFieldError?.has(selectedPU.tmpId)) {
            osExistsFieldError
              ?.get(selectedPU.tmpId)
              ?.set(item.tmpId, { billName: data.billNumber, item: item });
            errMap = new Map(osExistsFieldError);
          } else {
            osExistsFieldError.set(selectedPU.tmpId, puListErrors);
            errMap = new Map(osExistsFieldError);
          }

          showMessage(
            `Прибор с номером ${item?.mac || item?.number} уже существует в архиве, заявка ${
              data.billNumber
            }`
          );
        } else {
          osExistsFieldError?.get(selectedPU.tmpId)?.delete(item.tmpId);
          errMap = new Map(osExistsFieldError);
        }
        setOsExistsFieldError(errMap);
      })
      .catch((err) => catchError('Ошибка во время проверки дублей', { err }));
  };

  const checkReturnPuItemYodaTaskInfo = (item: SupplyPUItem) => {
    if (!selectedPU) return;
    const forcedTaskMacKey = `${selectedPU?.parentTypeName}-${item.mac}`;
    const forcedTaskNumberKey = `${selectedPU?.parentTypeName}-${item.number}`;
    if (
      (item.mac && forcedTaskNumberInfo.has(forcedTaskMacKey)) ||
      (item.number && forcedTaskNumberInfo.has(forcedTaskNumberKey)) ||
      item.backendItem
    )
      return;

    GetTaskByMeter({
      mac: item.mac,
      number: item.number,
      type: selectedPU?.parentTypeName,
    })
      .then(({ data }) => {
        if (item.mac) forcedTaskNumberInfo.set(forcedTaskMacKey, data[0]);
        if (item.number) forcedTaskNumberInfo.set(forcedTaskNumberKey, data[0]);
        setForcedTaskNumberInfo(new Map(forcedTaskNumberInfo));
        const setNoYodaTaskError = () => {
          const noTaskList = noYodaTaskErrorMap.get(selectedPU.tmpId);
          if (noTaskList) {
            noTaskList.set(item.tmpId, true);
            noYodaTaskErrorMap.set(selectedPU.tmpId, noTaskList);
            setNoYodaTaskErrorMap(new Map(noYodaTaskErrorMap));
          } else {
            const noTaskMap = new Map();
            noTaskMap.set(item.tmpId, true);
            noYodaTaskErrorMap.set(selectedPU.tmpId, noTaskMap);
            setNoYodaTaskErrorMap(new Map(noYodaTaskErrorMap));
          }
        };
        if (!data.length) {
          addActionLog(
            ActionLogType.WARNING,
            `Заявка для ПУ: ${selectedPU?.parentTypeName}, ${
              (item?.mac || item?.number) ?? ''
            } не найдена в YODA. Введите номер заявки вручную`
          );
          playAudioByKey('errorAudio');
        }
        if (data.length === 1) {
          if (returnNewItemsMode) {
            const firstRowAdress = puListItems[0]?.address;
            if (firstRowAdress !== data[0].originalAddress) {
              showMessage(
                `Заявка для ПУ: ${selectedPU?.parentTypeName}, ${
                  item?.mac || item?.number
                } не соответствует адресу массового возврата: ${firstRowAdress}`
              );
              setNoYodaTaskError();
            }
          } else {
            const noTaskList = noYodaTaskErrorMap.get(selectedPU.tmpId);
            if (noTaskList) {
              noTaskList.delete(item.tmpId);
              noYodaTaskErrorMap.set(selectedPU.tmpId, noTaskList);
              setNoYodaTaskErrorMap(new Map(noYodaTaskErrorMap));
            }
            return;
          }
        }
        if (data.length > 1) {
          showMessage(
            `Для ПУ: ${selectedPU?.parentTypeName}, ${
              (item?.mac || item?.number) ?? ''
            } найдено 2 заявки в YODA: ${data
              .map((item) => item.taskNumber)
              .join(', ')}. Обратитесь к администратору`
          );
          setNoYodaTaskError();
        }
      })
      .catch((err) => catchError('Ошибка во время поиска заявки с соответствующим ПУ', { err }));
  };

  const addNewRowIfNeeded = (rowId: number) => {
    if (!selectedPU) return;
    if (
      !puListItems.find(
        (it) =>
          (!it.mac || it.mac.length == 0) &&
          (!it.number || it.number.length == 0) &&
          rowId !== it.tmpId
      )
    ) {
      addNewRow(selectedPU.meterTypeId, selectedPU.meterTypeName);
    }
  };

  const addNewRow = (meterTypeId: number, meterTypeName: string) => {
    const rowScanId = generateScanId(selectedPU!, puListItems);
    const rowNumId = generateRowNum(selectedPU!, puListItems);
    const newPUList = puListItems.concat({
      tmpId: rowScanId,
      id: null,
      rowNum: rowNumId,
      meterTypeId: meterTypeId,
      meterTypeName: meterTypeName,
      number: null,
      mac: null,
      returnStorehouseAdded: workerAccess ? true : false,
    });

    setPUListItems(newPUList);
  };

  const openScaningMode = (pu: SupplyPU, disabled = false) => {
    const selectedPuMeterType = supplyMeterTypes.get(pu?.meterTypeId);
    const selectedPuScanField = selectedPuMeterType?.meterTypeScanField ?? '';
    const selectedPuName = selectedPuMeterType?.name ?? '';
    const isRim = selectedPuMeterType?.isRim ?? false;
    const parentTypeName =
      (selectedPuMeterType?.parentId
        ? supplyMeterTypes.get(selectedPuMeterType?.parentId)?.name
        : selectedPuMeterType?.name) ?? '';

    setSelectedPU({
      ...pu,
      meterTypeScanField: selectedPuScanField,
      meterName: selectedPuName,
      isRim,
      parentTypeName,
    });
    setSelectedPUDeclaredCount(pu!.declaredCount.toString());
    initScanningPUItems(pu, disabled);
    setIsScanningMode(true);
    setIsViewMode(disabled);
  };

  const initScanningPUItems = (pu: SupplyPU, viewMode?: boolean) => {
    const rowScanId = generateScanId(pu, puListItems);
    const rowNumId = generateRowNum(pu, puListItems);

    const tmpArr = pu!.puItems.map((elem) => ({ ...elem, savedItem: true }));
    const withoutAddingRow =
      viewMode || (billWithConfirm && workerAccess && pu.puItems.length) || props.accessProject;
    const viewModeTmpArr = withoutAddingRow
      ? tmpArr
      : tmpArr.concat({
          tmpId: rowScanId,
          id: null,
          rowNum: rowNumId,
          meterTypeId: pu.meterTypeId,
          meterTypeName: pu.meterTypeName,
          number: null,
          mac: null,
          savedItem: false,
          returnStorehouseAdded: !!workerAccess,
        });

    setPUListItems(viewModeTmpArr);
  };

  const setDeclareCount = (data: SupplyPU, val: any) => {
    const newPUList = puList.map((obj) => {
      if (obj.tmpId === data.tmpId) {
        return { ...obj, declaredCount: val };
      }
      return obj;
    });
    updatePUList(newPUList);
  };

  const setSelectedMeterType = (data: SupplyPU, val: SelectFilterOptType | null) => {
    let meterTypeId = 0;
    let meterTypeName = '';
    let puNumRegexExpr = '';
    let isRim = false;
    if (val) {
      const supplyMeterType = supplyMeterTypes.get(val.value);
      const meterType = meterTypes.find((it) => it.value === val.value);
      meterTypeId = Number(meterType?.value);
      meterTypeName = meterType!.label;
      puNumRegexExpr = supplyMeterType?.puNumRegexExpr ?? '';
      isRim = supplyMeterType?.isRim ?? false;
      setSelectedMeterTypes(selectedMeterTypesMap.set(data.tmpId, meterType!));
    } else {
      setSelectedMeterTypes(selectedMeterTypesMap.set(data.tmpId, null));
    }

    const newPUList = puList.map((obj) => {
      if (obj.tmpId === data.tmpId) {
        return {
          ...obj,
          meterTypeId,
          meterTypeName,
          puNumRegexExpr,
          isRim,
        };
      }
      return obj;
    });
    updatePUList(newPUList);
  };

  const updatePUList = (list: SupplyPU[]) => {
    setPUList(list);
  };

  const startScaning = (pu: SupplyPU) => {
    openScaningMode(pu);
    playAudioByKey('info');
  };

  const clearPuList = () => {
    const allUnsavedData = { ...getValue() };
    delete allUnsavedData[lsKey].puErrors;
    delete allUnsavedData[lsKey].pu;
    setValue(allUnsavedData, lsKey);

    setPUList([...initialPuList]);
    setSelectedMeterTypes(new Map());
    setOsDupFieldError(new Map());
    setOsExistsFieldError(new Map());
    setNoYodaTaskErrorMap(new Map());
  };

  const disabledOrDraftCheck =
    isViewMode ||
    nonEditableBill ||
    (billType !== SupplyConstant.RETURNED_TYPE &&
      billType !== SupplyConstant.DRAFT_TYPE_RETURN &&
      !workerAccess);

  const handleChangeDeclaredCount = (data: SupplyPU, event: TextFieldChangeEvent) => {
    const onlyNums = replaceNonNums(event?.target?.value);
    setDeclareCount(data, onlyNums);
  };

  const checkOptionDisable = (option: SelectFilterOptType) => {
    // Только в накладных возврата можно создавать дублирующиеся группы типов пу
    const alreadySelected = !!selectedMeterTypes.find((el) => el.value === option.value);
    if (!returnBill && alreadySelected) return true;

    if (!workerAccess && isChildType(option?.label)) {
      return true;
    }
    return false;
  };

  const disableRemoveScanner =
    disabledOrDraftCheck || !selectedMeterTypes.length || relocationGetBill;
  const disableSupplyClear = disabledOrDraftCheck || !puList.length || relocationGetBill;

  const currentComponent = (
    <form>
      {puList &&
        puList.map((val) => {
          const puListError = checkErrors(
            osDupFieldError,
            osExistsFieldError,
            noYodaTaskErrorMap,
            val?.tmpId
          );
          const hasNonConfirmedItems =
            billWithConfirm && workerAccess && !checkNonConfirmedItems(val);

          return (
            <Wrapper
              key={val.tmpId}
              unSavedItem={!val.backendItem}
              errorPus={puListError}
              allConfirmed={hasNonConfirmedItems}
            >
              <Grid
                key={val.tmpId}
                container
                item
                direction='row'
                justifyContent='center'
                alignItems='center'
                wrap='nowrap'
                marginBottom={'10px'}
              >
                <Autocomplete
                  disablePortal
                  id='meter-type-select'
                  disabled={nonEditableBill || val?.puItems?.length > 0}
                  value={selectedMeterTypesMap.get(val.tmpId) ?? null}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  options={meterTypes}
                  getOptionDisabled={checkOptionDisable}
                  noOptionsText={'Данных типов ПУ не найдено'}
                  sx={{ width: 200 }}
                  onChange={(e, value) => setSelectedMeterType(val, value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Тип'
                      variant='standard'
                    />
                  )}
                />
                <TextField
                  label='Заявленное количество'
                  disabled={nonEditableBill || relocationGetBill || props.accessProject}
                  InputLabelProps={{ shrink: true }}
                  value={val.declaredCount}
                  variant='standard'
                  sx={{ marginLeft: 3, width: 150 }}
                  onChange={(event) => handleChangeDeclaredCount(val, event)}
                />
                <ButtonStyled
                  variant='contained'
                  disabled={
                    !selectedMeterTypesMap.get(val.tmpId) ||
                    (disabledOrDraftCheck && !props.accessProject)
                  }
                  onClick={() => startScaning(val)}
                >
                  Сканировать
                </ButtonStyled>
                <ButtonStyled
                  variant='contained'
                  onClick={() => openScaningMode(val, true)}
                >
                  Просмотр
                </ButtonStyled>
                <TextField
                  label='Уже добавлено'
                  InputLabelProps={{ shrink: true }}
                  variant='standard'
                  disabled
                  value={(val?.puItems?.length || '')?.toString()}
                  style={{ marginLeft: '30px', width: '150px' }}
                />
                <IconButton
                  color='error'
                  disabled={disabledOrDraftCheck || relocationGetBill}
                  onClick={() => showRemoveConfirm(val.tmpId)}
                >
                  <DeleteForeverIcon />
                </IconButton>

                <IconButton
                  color='primary'
                  onClick={() =>
                    generatePuExcelFile(
                      val.puItems,
                      puOwnerOptions,
                      selectedMeterTypesMap.get(val.tmpId)?.label
                    )
                  }
                  disabled={!selectedMeterTypesMap.get(val.tmpId)}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Grid>
            </Wrapper>
          );
        })}
      <AlertRemove
        open={!!rowToRemove}
        handleClose={handleRemoveConfirmClose}
        handleSuccess={removeRow}
      />
      <SupplyButtonsWrapper>
        <IconButtonStyled
          disabled={disabledOrDraftCheck || relocationGetBill}
          onClick={() =>
            updatePUList(
              puList.concat({
                tmpId: generateNextId(puList),
                meterTypeId: -1,
                meterTypeName: '',
                puItems: [],
                declaredCount: '',
              })
            )
          }
        >
          <AddIcon />
        </IconButtonStyled>
        <PURemoveScanner
          removeItems={removePuByScanning}
          meterTypes={selectedMeterTypes}
          disabled={disableRemoveScanner}
          supplyMeterTypes={supplyMeterTypes}
        />
        <SupplyClear
          buttonText='Очистить все ПУ из накладной'
          modalText='Вы подтвержаете очистку всех ПУ в данной накладной?'
          onSuccess={clearPuList}
          disabled={disableSupplyClear}
        />
      </SupplyButtonsWrapper>
    </form>
  );

  const loading = returnBill
    ? isLoadingCompleteness ||
      isLoadingState ||
      isLoadingOwner ||
      isLoading ||
      isLoadingPuDefects ||
      isLoadingOsDefects
    : isLoading;

  const backendOptionsReady = returnBill
    ? puCompletenessOptions.length > 0 &&
      equipmentStateOptions.length > 0 &&
      puOwnerOptions.length > 0 &&
      puDefectOptions.length > 0 &&
      osDefectOptions.length > 0
    : meterTypes && meterTypes.length > 0;

  return (
    <div style={{ width: '100%', padding: '20px', marginBottom: '20px' }}>
      <SupplyBillBrowseLinearProgressWrapper>
        {loading && <LinearProgress />}
      </SupplyBillBrowseLinearProgressWrapper>

      {backendOptionsReady && currentComponent}
      <Modal
        open={isScanningMode}
        onClose={resetState}
        disableEscapeKeyDown
      >
        <SupplyPUModalInner>
          <SuppluPUModalHeader
            puListItems={puListItems}
            osDupFieldError={osDupFieldError}
            osExistsFieldError={osExistsFieldError}
            noYodaTaskFieldError={noYodaTaskErrorMap}
            disabledOrDraftCheck={disabledOrDraftCheck}
            selectedPU={selectedPU}
            returnBill={returnBill}
            selectedPUDeclaredCount={selectedPUDeclaredCount}
            savePUItems={savePUItems}
            closeScanning={resetState}
            setSelectedPUDeclaredCount={setSelectedPUDeclaredCount}
            removeErroredScanRows={removeErroredScanRows}
            confirmAllRows={confirmAllRows}
            viewMode={isViewMode}
            tabType={props.tabType}
            relocationGetBill={relocationGetBill}
            billWithConfirm={billWithConfirm}
            setPUListItems={setPUListItems}
            accessProject={props.accessProject}
          />

          <SupplyPUVirtualizedTable
            puListItems={puListItems}
            osDupFieldError={osDupFieldError}
            osExistsFieldError={osExistsFieldError}
            noYodaTaskFieldError={noYodaTaskErrorMap}
            disabledOrDraftCheck={disabledOrDraftCheck}
            selectedPU={selectedPU}
            returnBill={returnBill}
            billWithConfirm={billWithConfirm}
            relocationGetBill={relocationGetBill}
            removeScanRow={removeScanRow}
            handleSave={onEditCommit}
            onEditStart={onEditStart}
            showMessage={showMessage}
            confirmScanRow={confirmScanRow}
            confirmScanedNumber={confirmScanedNumber}
            viewMode={isViewMode}
            tabType={props.tabType}
            setPUListItems={setPUListItems}
            accessProject={props.accessProject}
          />
        </SupplyPUModalInner>
      </Modal>
      <AlertError
        alertText={alertText}
        open={!!alertText}
        handleClose={handleClose}
      />
    </div>
  );
};

export default memo(SupplyPUComponent);
